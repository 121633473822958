<template>
  <v-dialog
    class="overflow-visible"
    no-click-animation
    max-width="1000"
    :value="show"
    persistent
    scrollable
  >
    <close-button @click="$emit('cancel')" overflow />

    <v-card v-if="recipe">
      <v-card-title class="v-card__title white px-5 justify-space-between align-center">
        <span class="font-weight-bold headline black--text">{{ recipe.name }}</span>
        
        <v-spacer></v-spacer>

      </v-card-title>
      <v-card-text class="pt-5 pb-7">

        <v-row>
          <v-col cols="12" md="7" lg="7" xl="7">
            <div v-if="recipe?.newData?.newName" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Name
              </h5>

              <p class="font-weight-bold">{{ recipe?.newData?.newName }}</p>
            </div>
            <!-- INGREDIENTS -->
            <v-card v-if="recipe.ingredients && recipe.ingredients.length" class="mb-5" flat>
              <v-simple-table class="grey lighten-4" bordered dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="mb-5 subtitle-1 font-weight-bold blue--text">Ingredients</th>
                      <th>Serve Amount</th>
                      <th>Serve Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="recipeIngredient in orderBy(recipe.ingredients, 'order', 'asc')" :key="recipeIngredient.id">
                      <td class="text-capitalize py-2" width="40%">{{ recipeIngredient.food }}</td>
                      <td v-if="recipe.lock_serve" width="30%">{{ parseFloat(recipeIngredient.quantity) }}</td>
                      <td v-else-if="mealPrep">0</td>
                      <td v-else width="30%">{{ parseFloat((recipeIngredient.quantity * ((serves || recipe.numberOfServes) / recipe.numberOfServes)).toFixed(2)) }}</td>
                      <td width="30%">{{ recipeIngredient.portion }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <div v-if="recipe?.newData?.newIng?.length" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Ingredients
              </h5>

              <ul>
                <li v-for="(ing, i) in recipe?.newData?.newIng" 
                class="mb-1 pre-wrap"
                :key="i"
                >
                {{ ing }}
                </li>
              </ul>
            </div>

            <div v-if="recipe.removedData.removedIng.length" class="mb-5 red lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold red--text text--darken-1">
                <i class="mdi mdi-minus-circle"></i>
                Removed Ingredients
              </h5>

              <ul>
                <li v-for="(ing, i) in recipe.removedData.removedIng" 
                class="mb-1 pre-wrap "
                :key="i"
                >
                {{ ing }}
                </li>
              </ul>
            </div>

            <p v-if="recipe.lock_serve" class="mb-5 font-weight-bold">
              Makes {{ recipe.numberOfServes  }} Serves ({{ recipe.servingValues.weight | numberFormat }}g per serve)
            </p>
            <p v-else-if="recipe.servingValues" class="mb-5 font-weight-bold">
              Makes {{ serves || recipe.numberOfServes }} Serves ({{ recipe.servingValues.weight | numberFormat }}g per serve)
            </p>

            <!-- MEAL TIME -->
            <div v-if="recipe.mealTime && recipe.mealTime.length" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold blue--text">
                Meal Time
              </h5>

              <p>{{ recipe.mealTime.join(', ') }}</p>
            </div>

            <div v-if="recipe?.newData.newTimes.length" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Meal Times
              </h5>

              <p class="font-weight-bold">{{ recipe?.newData.newTimes.join(', ') }}</p>
            </div>

            <div v-if="recipe.removedData.removedTimes.length" class="mb-5 red lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold red--text text--darken-1">
                <i class="mdi mdi-minus-circle"></i>
                Removed Meal Times
              </h5>

              <p class="font-weight-bold">{{ recipe.removedData.removedTimes.join(', ') }}</p>
            </div>

            <!-- VIDEO -->

            <div v-if="recipe.video" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold blue--text">
                Video
              </h5>

              <a :href="recipe.video" target="_blank">{{ recipe.video }}</a>
            </div>
            <div v-if="recipe?.newData.newVideo" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Video URL
              </h5>

              <p class="font-weight-bold">{{ recipe?.newData.newVideo }}</p>
            </div>

            <!-- DESCRIPTION -->
            <div v-if="recipe.description" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold blue--text">
                Description
              </h5>

              <p class="pre-wrap">{{ recipe.description }}</p>
            </div>

            <div v-if="recipe?.newData.newDesc" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Description 
              </h5>

              <p class="font-weight-bold">{{ recipe?.newData.newDesc }}</p>
            </div>

            <!-- METHODS -->
            <div v-if="recipe.methods && recipe.methods.length" class="mb-5">
              <h5 class="mb-2 subtitle-1 font-weight-bold blue--text">
                Methods
              </h5>

              <div v-if="mealPrep">This is a pre-prepared meal for the purpose of leftovers or meal prepping. Please refer to the original recipe for quantities and cooking method.</div>

              <ol v-else>
                <li
                  v-for="(method, i) in recipe.methods"
                  :key="i"
                  class="mb-1 pre-wrap"
                >
                  {{ method }}
                </li>
              </ol>
            </div>

            <div v-if="recipe?.newData.newMethods.length" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Methods
              </h5>

              <ul>
                <li v-for="(method, i) in recipe?.newData.newMethods" 
                class="mb-1 pre-wrap"
                :key="i"
                >
                {{ method }}
                </li>
              </ul>
            </div>

            <div v-if="recipe.removedData.removedMethods.length" class="mb-5 red lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold red--text text--darken-1">
                <i class="mdi mdi-minus-circle"></i>
                Removed Methods
              </h5>

              <ul>
                <li v-for="(method, i) in recipe.removedData.removedMethods" 
                class="mb-1 pre-wrap "
                :key="i"
                >
                {{ method }}
                </li>
              </ul>
            </div>

            <!-- ANALYSIS SUMMARY -->
            <div>
              <h5 class="mb-2 subtitle-1 font-weight-bold blue--text">
                Analysis Summary
              </h5>

              <v-card flat>
                <v-simple-table class="grey lighten-4" id="nutrient-table" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th width="33%"></th>
                        <th width="33%">Quantity Per Serving</th>
                        <th width="33%">Quantity Per 100g</th>
                      </tr>
                    </thead>
                    <tbody v-if="recipe.servingValues">
                      <tr>
                        <td>Weight (g)</td>
                        <td>{{ recipe.servingValues.weight | amount | numberFormat }}g</td>
                        <td>100.00g</td>
                      </tr>
                      <tr>
                        <td>Energy {{ user.energyUnit == 'cal' ? '(Cal)' : '(kJ)' }}</td>
                        <td v-if="user.energyUnit == 'cal'">{{ recipe.servingValues.energy | kjToCal | amount | numberFormat }}Cal</td>
                        <td v-else>{{ recipe.servingValues.energy | amount | numberFormat }}kJ</td>
                        <td v-if="user.energyUnit == 'cal'">{{ recipe.servingValuesPerHundredGram.energy | kjToCal }}Cal</td>
                        <td v-else>{{ recipe.servingValuesPerHundredGram.energy }}kJ</td>
                      </tr>
                      <tr>
                        <td>Protein (g)</td>
                        <td>{{ recipe.servingValues.protein | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.protein }}g</td>
                      </tr>
                      <tr>
                        <td>Fat (g)</td>
                        <td>{{ recipe.servingValues.fat | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.fat }}g</td>
                      </tr>
                      <tr>
                        <td>Carbohydrate (g)</td>
                        <td>{{ recipe.servingValues.carbohydrate | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.carbohydrate }}g</td>
                      </tr>
                      <tr>
                        <td>Sugar (g)</td>
                        <td>{{ recipe.servingValues.sugar  | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.sugar }}g</td>
                      </tr>
                      <tr>
                        <td>Fibre (g)</td>
                        <td>{{ recipe.servingValues.fibre  | amount | numberFormat }}g</td>
                        <td>{{ recipe.servingValuesPerHundredGram.fibre }}g</td>
                      </tr>
                      <tr>
                        <td>Sodium (mg)</td>
                        <td>{{ recipe.servingValues.sodium  | amount | numberFormat }}mg</td>
                        <td>{{ recipe.servingValuesPerHundredGram.sodium }}mg</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" md="5" lg="5" xl="5">
            <RecipeImage :recipe="recipe" @url="setImageUrl($event)" size="medium" />

            <div class="mt-5">
              <div class="font-weight-bold mb-2 blue--text">Meal Tags</div>

              <span
                v-for="(tag, i) in tagsConf"
                :key="i"
              >
                <v-chip
                  label
                  small
                  class="mr-1 mb-1"
                  v-if="$store.getters['tags/checkIfUnpublish'](tag)"
                >{{ $store.getters['tags/getName'](tag) }}</v-chip>
              </span>
            </div>

            <div v-if="recipe?.newData.newTags.length" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Meal Tags
              </h5>

              <ul>
                <li v-for="(tag, i) in recipe?.newData.newTags" 
                class="mb-1 pre-wrap"
                :key="i"
                >
                {{ $store.getters['tags/getName'](tag)  }}
                </li>
              </ul>
            </div>

            <div v-if="recipe.removedData.removedTags.length" class="mb-5 red lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold red--text text--darken-1">
                <i class="mdi mdi-minus-circle"></i>
                Removed Meal Tags
              </h5>

              <ul>
                <li v-for="(tag, i) in recipe.removedData.removedTags" 
                class="mb-1 pre-wrap"
                :key="i"
                >
                {{ $store.getters['tags/getName'](tag)  }}
                </li>
              </ul>
            </div>
            
            <div class="mt-3">
              <div class="font-weight-bold mb-2 blue--text">Nutrition Tags</div>

              <span
                v-for="(tag, i) in recipe.nutritionTags"
                :key="i"
              >
                <v-chip
                  label
                  small
                  class="mr-1 mb-1"
                  v-if="$store.getters['nutritiontags/checkIfUnpublish'](tag)"
                >{{ $store.getters['nutritiontags/tagData'](tag).name }}</v-chip>
              </span>
            </div>

            <div v-if="recipe?.newData.newNutriTags.length" class="mb-5 green lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold green--text text--darken-1">
                <i class="mdi mdi-plus-circle"></i>
                New Nutrition Tags
              </h5>

              <ul>
                <li v-for="(tag, i) in recipe?.newData.newNutriTags" 
                class="mb-1 pre-wrap"
                :key="i"
                >
                {{ $store.getters['nutritiontags/tagData'](tag).name }}
                </li>
              </ul>
            </div>

            <div v-if="recipe.removedData.removedNutriTags.length" class="mb-5 red lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold red--text text--darken-1">
                <i class="mdi mdi-minus-circle"></i>
                Removed Nutrition Tags
              </h5>

              <ul>
                <li v-for="(tag, i) in recipe.removedData.removedNutriTags" 
                class="mb-1 pre-wrap"
                :key="i"
                >
                {{ $store.getters['nutritiontags/tagData'](tag).name }}
                </li>
              </ul>
            </div>

            <v-card class="my-5" flat color="grey lighten-4">
              <v-card-text>
                <div class="d-flex align-center">
                  <div>
                    <h4 class="font-weight-bold blue--text">Requested by:</h4>
                    <div class="font-weight-bold">{{ recipe.customized_by_name }} </div>
                    <div class="caption font-weight-bold">{{ determineRole(recipe.customized_by_role)}} </div>
                    <div class="caption">
                      Requested At {{ recipe.createdAt | formatDate }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <div v-if="recipe.status === 'declined'" class="mb-5 red lighten-3 px-3">
              <h5 class="subtitle-1 font-weight-bold red--text text--darken-1">
                <i class="mdi mdi-minus-circle"></i>
                Declined by
              </h5>

              <p class="font-weight-bold">{{ getUser(recipe.user) }}</p>
            </div>

            <v-row v-if="user.role === 'admin'" style="gap: 0.5rem;" class="mt-3 px-3">
              <v-btn @click="approveAdmin(recipe)" :loading="status.approving" class="text-none" color="primary" small depressed>
                <span>Accept</span>
                
                <v-tooltip max-width="200" right>
                  <template #activator="{ on }">
                    <v-icon v-on="on" small right>mdi-check-circle-outline</v-icon>
                  </template>
                  <span>This will become one of your Recipes</span>
                </v-tooltip>
              </v-btn>

              <v-btn @click="declineAdmin(recipe)" :loading="status.deleting" class="text-none" color="error" small depressed>
                <span>Delete Recipe</span>
                
                <v-tooltip max-width="200" right>
                  <template #activator="{ on }">
                    <v-icon v-on="on" small right>mdi-check-circle-outline</v-icon>
                  </template>
                  <span>This will remove the recipe from the database</span>
                </v-tooltip>
              </v-btn>
            </v-row>


            <v-row v-else style="gap: 0.5rem;" class="mt-3 px-3">
              <v-btn @click="approve(recipe)" :loading="status.approving" class="text-none" color="primary" small depressed>
                <span>Accept</span>
                
                <v-tooltip max-width="200" right>
                  <template #activator="{ on }">
                    <v-icon v-on="on" small right>mdi-check-circle-outline</v-icon>
                  </template>
                  <span>This will become one of your Recipes</span>
                </v-tooltip>
              </v-btn>

              <v-btn @click="decline(recipe)" :loading="status.deleting" class="text-none" color="error" small depressed>
                <span>Decline</span>
                
                <v-tooltip max-width="200" right>
                  <template #activator="{ on }">
                    <v-icon v-on="on" small right>mdi-check-circle-outline</v-icon>
                  </template>
                  <span>This will send a message to the user saying you have declined the request</span>
                </v-tooltip>
              </v-btn>
            </v-row>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    
  </v-dialog>
</template>

<script>
// PLUGINS
import { mapState, mapActions, mapGetters } from 'vuex'
import { orderBy, startCase } from 'lodash'

// COMPONENTS
import RecipeImage from '@/components/RecipeImage'

// MIXINS
import downloadRecipe from '@/mixins/download-recipe-pdf'

export default {
  name: 'RecipeCard',

  mixins: [downloadRecipe],

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    recipe: {
      type: [Object, null],
    },
    show: Boolean,
    rate: Boolean,
    serves: Number,
    mealPrep: Boolean,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecipeImage,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      startCase,
      requesting: false,
      measurements: null,
      downloading: false,
      image: '/placeholder.svg',
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      recipeIngredients: state => state.recipe.ingredients,
      status: state => state.recipes.status
    }),

    ...mapGetters('members', [
      'canAddRecipeMembers'
    ]),

    gramPerServe: function () {
      return Math.round(this.getTotalWeight)
    },

    tagsConf: function () {
      if (this.recipe && this.recipe.tags) {
        return this.recipe.tags.filter(tag => {
          return this.$store.getters['tags/getName'](tag)
        })
      }
      else return []
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('recipe', ['customizeRecipe']),
    ...mapActions('recipes', ['approveCustomRecipe', 'declineRecipe', 'approveDeclinedRecipe', 'deleteDeclinedRecipe']),
    setImageUrl(e) {
      this.image = e
    },

    startDownload() {
      this.downloading = true

      Promise.resolve(this.downloadPDF())
      .then(() => {
        this.downloading = false
      })
    },

    addFixedDecimals(val) {
      if(val % 1 != 0) 
        return this.$options.filters.amount(val)

      return val
    },

    determineRole (role) {
      return role === 'personal_trainer' ? 'Health & Fitness Professionals' : startCase(role)
    },

    approve(recipe) {
      this.approveCustomRecipe(recipe)
      .finally(() => {
        this.$emit('cancel')
      })
    },

    decline(recipe) {
      this.declineRecipe(recipe)
      .finally(() => {
        this.$emit('cancel')
      })
    },
    approveAdmin(recipe) {
      this.approveDeclinedRecipe(recipe)
      .finally(() => {
        this.$emit('cancel')
      })
    },

    declineAdmin(recipe) {
      this.deleteDeclinedRecipe(recipe)
      .finally(() => {
        this.$emit('cancel')
      })
    },
    getUser(id) {
      let user = this.canAddRecipeMembers.find(user => user.id == id)

      if (user) {
        return `${user.firstName} ${this.$options.filters.sliceText(user.lastName)}`
      }
      else if (this.noneExisting.includes(id)) {
        return 'non-existent'
      }

    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.$store.state.tags.status.firstLoad) this.$store.dispatch('tags/getTags')    
    if (!this.$store.state.nutritiontags.status.firstLoad) this.$store.dispatch('nutritiontags/getTags')
  }

}

</script>

<style>

#nutrient-table tr th:not(:first-child),
#nutrient-table tr td:not(:first-child) {
  text-align: right;
}

</style>
